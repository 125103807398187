import Vue from 'vue';

const LANGUAGE_CODE = 'languageCode';
const CHOSEN_VERSION = 'chosenVersion';
const LOADED_VERSION = 'loadedVersion';
const BIBLE_DATA = 'bibleData';
const BIBLE_REFS = 'bibleRefs';
const GAME_VERSES = 'gameVerses';
const SCORE = 'score';

const setVariable = (key, value) => { Vue.prototype['$' + key] = value };
const getVariable = (key       ) =>   Vue.prototype['$' + key];

const setLanguageCode = (value) => { setVariable(LANGUAGE_CODE, value); }
const getLanguageCode = (     ) =>   getVariable(LANGUAGE_CODE);

const setChosenVersion = (value) => { setVariable(CHOSEN_VERSION, value); }
const getChosenVersion = (     ) =>   getVariable(CHOSEN_VERSION);

const setLoadedVersion = (value) => { setVariable(LOADED_VERSION, value); }
const getLoadedVersion = (     ) =>   getVariable(LOADED_VERSION);

const setBibleData = (value) => { setVariable(BIBLE_DATA, value); }
const getBibleData = (     ) =>   getVariable(BIBLE_DATA);

const setBibleRefs = (value) => { setVariable(BIBLE_REFS, value); }
const getBibleRefs = (     ) =>   getVariable(BIBLE_REFS);

const setGameVerses = (value) => { setVariable(GAME_VERSES, value); }
const getGameVerses = (     ) =>   getVariable(GAME_VERSES);

const setScore = (round, value) => { let score = getVariable(SCORE) || {}; score[round] = value; setVariable(SCORE, score); }
const getScore = () => getVariable(SCORE);
const clearScore = () => { setVariable(SCORE, undefined); }

// setRoundVerse(i, value), setRoundScore(i, value)

const getBibleVerseRange = (from, to) => {
  const bible = getBibleData();
  let verses = [];
  if (from < bible.from) from = bible.from;
  if (to   > bible.to  ) to   = bible.to;
  
  let t = -1, b = -1, c = -1;
  while (from > bible.testaments[++t].to);
  let testament = bible.testaments[t];
  while (from > testament.books[++b].to);
  let book = testament.books[b];
  while (from > book.chapters[++c].to);
  let chapter = book.chapters[c];
  let v = from - chapter.from;
  let verse = chapter.verses[v];
  while (verse.index <= to) {
    verses.push({
      index: verse.index,
      value: verse.value,
      indexInChapter: v,
      testamentIndex: t,
      bookName: book.value,
      bookIndexInTestament: b,
      chapterIndexInBook: c,
      fullRef: book.value + ' ' + (c + 1) + ':' + (v + 1),
    });
    if (v  + 1 < chapter.verses.length) {
      verse = chapter.verses[++v];
    } else if (c + 1 < book.chapters.length) {
      chapter = book.chapters[++c];
      verse = chapter.verses[v = 0];
    } else if (b + 1 < testament.books.length) {
      book = testament.books[++b];
      chapter = book.chapters[c = 0];
      verse = chapter.verses[v = 0];
    } else if (t + 1 < bible.testaments.length) {
      testament = bible.testaments[++t];
      book = testament.books[b = 0];
      chapter = book.chapters[c = 0];
      verse = chapter.verses[v = 0];
    } else break;
  }

  return verses;
}
const getBibleVerse = (index) => getBibleVerseRange(index, index)[0];

export default {
  LANGUAGE_CODE,
  CHOSEN_VERSION,
  LOADED_VERSION,
  BIBLE_DATA,
  BIBLE_REFS,
  GAME_VERSES,
  SCORE,

  setVariable,
  getVariable,

  setLanguageCode,
  getLanguageCode,
  setChosenVersion,
  getChosenVersion,
  setLoadedVersion,
  getLoadedVersion,
  setBibleData,
  getBibleData,
  setBibleRefs,
  getBibleRefs,
  setGameVerses,
  getGameVerses,
  setScore,
  getScore,
  clearScore,

  getBibleVerseRange,
  getBibleVerse,
}