var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component home justify-around",attrs:{"id":"container"}},[_c('header',[_c('img',{attrs:{"id":"logo","alt":"BibleGuesser logo","src":require("../assets/logo/logo2.svg")}}),_c('p',{attrs:{"id":"version"}},[_vm._v("v"+_vm._s(_vm.getVersion(0, 1)))])]),_c('main',[_c('bg-scroll-picker',{staticClass:"width-default",attrs:{"values":_vm.versionValues,"defaultSelection":_vm.versionDefault,"flex":[2, 3],"textAlign":['left', 'center'],"big":true},on:{"select-all":_vm.onSelectVersion}})],1),_c('footer',[_c('bg-navigation',{attrs:{"buttons":[
      {type: 'icon', event: _vm.HELP_EVENT, value: 'help', size: 'small'}, // TODO tooltip about
      {type: 'text', event: _vm.PLAY_EVENT, value: _vm.tr('home.navigation.play', [], _vm.languageCode), size: 'big', uppercase: true},
      {type: 'icon', event: _vm.SHARE_EVENT, value: 'share-variant', size: 'small', disabled: true} ]},on:{"click":_vm.onNavigate}})],1),_c('bg-modal',{ref:"helpModal",attrs:{"show":_vm.showHelpModal,"buttons":[],"closable":true},on:{"close":_vm.onHelpModalClose}},[_c('bg-tabs',{attrs:{"tabs":[
      { 'id': 'rules', 'label': _vm.tr('home.help-modal.rules-tab.title', [], _vm.languageCode), selected: true },
      { 'id': 'about', 'label': _vm.tr('home.help-modal.about-tab.title', [], _vm.languageCode) } ]},scopedSlots:_vm._u([{key:"rules",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.tr('home.help-modal.rules-tab.content:html', [], _vm.languageCode))}})]},proxy:true},{key:"about",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.Package.name)),_c('br'),_c('span',{staticClass:"subtitle"},[_vm._v("v"+_vm._s(_vm.getVersion(0, 2))+_vm._s(_vm.getVersionName() ? ' "' + _vm.getVersionName() + '"' : ""))])]),_c('div',{staticClass:"verse"},[_c('p',{staticClass:"verse-value",domProps:{"innerHTML":_vm._s(_vm.tr('home.help-modal.about-tab.verse.value', [], _vm.languageCode))}}),_c('br'),_c('p',{staticClass:"verse-reference",domProps:{"innerHTML":_vm._s('- ' + _vm.tr('home.help-modal.about-tab.verse.reference', [], _vm.languageCode))}})]),_c('p',{staticClass:"center developed-by",domProps:{"innerHTML":_vm._s(_vm.tr('home.help-modal.about-tab.developed-by', [], _vm.languageCode))}}),_c('p',{staticClass:"center hosted-by",domProps:{"innerHTML":_vm._s(_vm.tr('home.help-modal.about-tab.hosted-by', [], _vm.languageCode))}}),_c('h2',[_vm._v(_vm._s(_vm.tr("home.help-modal.about-tab.acknowledgments.title", [], _vm.languageCode)))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.tr('home.help-modal.about-tab.acknowledgments.content:html', [], _vm.languageCode))}})]},proxy:true}])})],1),_c('bg-modal',{ref:"domainModal",attrs:{"show":!_vm.showUpdateModal && _vm.showDomainModal,"buttons":[
    {type: 'text+icon', value: ['BibleGuesser.com', 'open-in-new'], event: 'goToProduction'}
  ],"closable":true},on:{"click":_vm.onDomainModalClick,"close":_vm.onDomainModalClose}},[_c('h2',[_c('span',{staticClass:"flag-icon flag-icon-fr margin-right-10"}),_vm._v("Environnement de test")]),_c('p',[_vm._v("Vous êtes sur un environnement de test. Rendez-vous sur "),_c('em',[_vm._v("BibleGuesser.com")]),_vm._v(" pour profiter de la version publique de BibleGuesser !")]),_c('h2',[_c('span',{staticClass:"flag-icon flag-icon-gb margin-right-10"}),_vm._v("Test environment")]),_c('p',[_vm._v("You are on the test environment. Please go to "),_c('em',[_vm._v("BibleGuesser.com")]),_vm._v(", so you'll be able to enjoy the public version of BibleGuesser!")])]),_c('bg-modal',{ref:"updateModal",attrs:{"show":_vm.showUpdateModal,"buttons":[
    {type: 'text+icon', value: ['Mettre à jour / Update', 'refresh'], event: 'update'}
  ]},on:{"click":_vm.onUpdateModalClick}},[_c('h2',[_c('span',{staticClass:"flag-icon flag-icon-fr margin-right-10"}),_vm._v("Mise à jour")]),_c('p',[_vm._v("Une mise à jour est disponible ! "),_c('em',[_vm._v(_vm._s(_vm.updateVersion ? _vm.updateVersion : ""))])]),_c('h2',[_c('span',{staticClass:"flag-icon flag-icon-gb margin-right-10"}),_vm._v("Update")]),_c('p',[_vm._v("An update is available! "),_c('em',[_vm._v(_vm._s(_vm.updateVersion ? _vm.updateVersion : ""))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }