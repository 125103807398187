<template>
  <div class="component modal" :class="show ? 'show' : 'hide'">
    <div class="modal-background" @click="closable && onClose()"></div>
    <div class="modal-window glass" :class="{'small': small}">
      <div class="modal-close" v-if="closable" @click="onClose()">
        <mdi-svg-icon :id="'close'" :size="24" :color="'primary'"></mdi-svg-icon>
      </div>
      <h1 v-if="title">{{ title }}</h1>
      <slot></slot>
      <div class="buttons">
        <button v-for="button in buttons" :key="button.event" class="neu small primary" :class="{'secondary': button.secondary}" v-on:click="onClick(button)">
          <span v-if="button.type === 'text'" :class="button.uppercase ? 'uppercase' : null">{{ button.value }}</span>
          <mdi-svg-icon v-if="button.type === 'icon'" :id="button.value" :size="24" :color="'white'"></mdi-svg-icon>
          <span v-if="button.type === 'text+icon'" :class="button.uppercase ? 'uppercase' : null">{{ button.value[0] }}
            <mdi-svg-icon :id="button.value[1]" :size="16" :color="'white'"></mdi-svg-icon>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>



<script>
import MdiSvgIcon from "@/components/MdiSvgIcon.vue";

const CLICK_EVENT = "click";
const CLOSE_EVENT = "close";

export default {
  name: 'BgModal',

  emits: [CLICK_EVENT, CLOSE_EVENT],

  components: {
    MdiSvgIcon,
  },

  props: { 
    title: {type: String, required: false},
    buttons: {type: Array, required: true, validator: function(value) {
      let valid = true;
      for (const item of value) {
        valid = valid && ["icon", "text", "text+icon"].includes(item.type);
      }
      return valid;
    }},
    show: {type: Boolean, default: true},
    closable: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
  },

  methods: {
    onClick(button) {
      this.$emit(CLICK_EVENT, button.event);
    },
    onClose() {
      this.$emit(CLOSE_EVENT);
    }
  }
}
</script>



<style lang="less" scoped>
@import "../styles/variables.less";
@import "../styles/glassmorphism.less";
@import "../styles/layout.less";
@import "../styles/neumorphism.less";

div.component.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -15;

  div.modal-background {
    background: fade(@background-color, 50%);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0s;
  }

  div.modal-window {
    opacity: 0;
    position: fixed;
    width: calc(@max-width - 100px);
    left: calc(50% - (@max-width - 100px)/2);
    @media (max-width: @max-width) { width: 80%; left: 10%; }
    height: calc(100% - 100px);
    max-height: 400px;
    @media (max-height: 500px) { // .show top + 50px (up movement)
      top: 100px;
    }
    @media (min-height: 500px) { 
      top: calc(50% - 150px); // .show top + 50px (up movement)
    }

    &.small {
      width: calc(@max-width - 200px);
      left: calc(50% - (@max-width - 200px)/2);
      @media (max-width: @max-width) { width: 60%; left: 20%; }
      height: calc(100% - 200px);
      max-height: 300px;
      @media (max-height: 500px) { 
        top: 150px;
      }
      @media (min-height: 500px) { 
        top: calc(50% - 100px);
      }

      button {
        margin: 0;
      }
    }

    padding: 20px;
    text-align: center;
    transition: opacity 0s, top 0s;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-around;

    font-size: 1em;
    @media (max-width: 500px) { font-size: calc(0.9*1em); }
    @media (max-width: 400px) { font-size: calc(0.8*1em); }
    @media (max-width: 300px) { font-size: calc(0.7*1em); }
    @media (max-width: 200px) { font-size: calc(0.6*1em); }

    & > * {
      margin: 0;
    }

    & > .modal-close {
      cursor: pointer;
      position: absolute;
      padding: 5px;
      top: 0;
      right: 0;
    }

    button {
      display: inline-block;
      line-height: 20px;
      font-size: 110%;

      span svg { // text+icon
        display: inline;
        vertical-align: middle;
        margin-left: 10px;
      }

      &.secondary {
        background: none;
        border: none;
        box-shadow: none;
        color: @primary-color;
      }
    }
  }

  &.show {
    z-index: 15;

    div.modal-background {
      opacity: 1;
      transition: opacity 0.1s;
    }

    div.modal-window {
      opacity: 1;
      @media (max-height: 500px) { 
        top: 50px;
      }
      @media (min-height: 500px) { 
        top: calc(50% - 200px);
      }

      &.small {
        @media (max-height: 500px) { 
          top: 100px;
        }
        @media (min-height: 500px) { 
          top: calc(50% - 150px);
        }
      }

      transition: opacity 0.1s, top 0.1s;
    }
  }
}
</style>