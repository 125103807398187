var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component game-round justify-between",attrs:{"id":"container"}},[_c('main',[_c('p',{staticClass:"bible-font fade"},[_vm._v(" "+_vm._s(_vm.getVersesString(-100, -1))+" "),_c('em',[_vm._v(_vm._s(_vm.$gameVerses[_vm.$route.params.round - 1].value))]),_vm._v(" "+_vm._s(_vm.getVersesString(+1, +100))+" ")])]),_c('footer',[_c('bg-scroll-picker',{attrs:{"values":_vm.refsValues,"defaultSelection":_vm.defaultSelection,"flex":[5, 1, 1],"textAlign":['left', 'right', 'right']},on:{"select-all":_vm.onSelectRef,"select-level":_vm.onSelectPartialRef}}),_c('bg-navigation',{attrs:{"buttons":[
      {type: 'icon', event: 'abort', value: 'home', size: 'small'},
      {type: 'icon', event: 'submit', value: 'check-bold', size: 'medium'},
      {type: 'icon', event: 'center', value: 'target', size: 'small'} ]},on:{"click":_vm.onNavigate}})],1),_c('bg-modal',{attrs:{"title":_vm.tr('game-round.result-modal.title'),"show":_vm.showResultModal,"buttons":[
    {type: 'text', value: _vm.tr('game-round.result-modal.' + (_vm.hasNextRound() ? 'next-round' : 'show-results')), event: 'next'}
  ]},on:{"click":_vm.onResultModalClick}},[(_vm.score)?_c('p',{staticClass:"score-points"},[_vm._v(_vm._s(_vm.score.points)),_c('span',{staticClass:"max-score"},[_vm._v(" / 100")])]):_vm._e(),(_vm.score)?_c('p',{staticClass:"score-stars"},[_c('span',{staticClass:"tooltip"},_vm._l((['book', 'chapter', 'verse']),function(entity,index){return _c('span',{key:index},[(_vm.score.stars > index)?_c('mdi-svg-icon',{attrs:{"id":'check-circle',"size":16,"color":'green'}}):_c('mdi-svg-icon',{attrs:{"id":'close-circle',"size":16,"color":'red'}}),_vm._v(" "+_vm._s(_vm.tr('stars-tooltip.' + entity))+" ")],1)}),0),_vm._l((['book', 'chapter', 'verse']),function(entity,index){return _c('span',{key:index},[(_vm.score.stars > index)?_c('mdi-svg-icon',{attrs:{"id":'star',"size":32,"color":'yellow'}}):_c('mdi-svg-icon',{attrs:{"id":'star-outline',"size":32,"color":'gray'}})],1)})],2):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.tr('result-summary:html', [_vm.selectedVerse ? _vm.selectedVerse.fullRef : '', _vm.roundVerse ? _vm.roundVerse.fullRef : '']))}})]),_c('bg-modal',{ref:"abortModal",attrs:{"show":_vm.showAbortModal,"closable":true,"small":true,"buttons":[
    {type: 'text', value: _vm.tr('game-round.abort-modal.cancel'), event: 'cancel', secondary: true},
    {type: 'text', value: _vm.tr('game-round.abort-modal.confirm'), event: 'confirm'}
  ]},on:{"click":_vm.onAbortModalClick,"close":_vm.onAbortModalClose}},[_c('h2',[_vm._v(_vm._s(_vm.tr("game-round.abort-modal.title"/*"Êtes-vous sûr ?"*/)))]),_c('p',[_vm._v(_vm._s(_vm.tr("game-round.abort-modal.content"/*"Vous êtes sur le point d'abandonner la partie en cours et de perdre le score en cours."*/)))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }