<template>
  <div id="container" class="component game-creation justify-around">
    <main>
      <loader :title="loadingVersion ? loadingVersion.name : ''" :legend="tr('game-creation.loading-version')"></loader>
    </main>
  </div>
</template>



<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";
import GlobalManager from "@/managers/global.manager.js";
import VersionService from "@/services/version.service.js";
import { random, isStandalone, getMobileOS } from "@/helpers.js";

export default {
  name: 'BgGameCreation',

  components: {
    Loader, 
  },

  beforeRouteEnter(to, from, next) {
    // https://router.vuejs.org/fr/guide/advanced/navigation-guards.html#interception-par-composant
    if (!GlobalManager.getLanguageCode() || !GlobalManager.getChosenVersion()) next("/");
    else next();
  },

  data() {
    return {
      loadingVersion: null,
    }
  },

  mounted: function() {
    axios.get("https://ipapi.co/json")
      .then(response => this.postAnalytics(response.data))
      .catch(() => this.postAnalytics(null));
    
    this.loadVersion().then(() => {
      this.createGame();
    }).catch(() => {
      this.$router.push("/");
    });
  },

  methods: {
    postAnalytics(ipData) {
      axios // https://cli.vuejs.org/guide/mode-and-env.html#example-staging-mode
        .post(process.env.VUE_APP_ANALYTICS_URL, new URLSearchParams({
          country_code: ipData && ipData.country_code ? ipData.country_code.toLowerCase().substr(0, 2) : null,
          language_code: GlobalManager.getLanguageCode().toLowerCase(),
          version_code: GlobalManager.getChosenVersion().toLowerCase(),
          first_visit: !GlobalManager.getLoadedVersion(),
          mobile_os: getMobileOS().toLowerCase(),
          pwa: isStandalone(),
          // status: 'started',
        }))
        .then(() => {}) // TODO: Get back an anonymous GUID and stored it, in order to update on aborted or completed event + put score
        .catch(() => {});
    },

    loadVersion() { 
      return new Promise((resolve, reject) => {
        const loadedVersion = GlobalManager.getLoadedVersion();
        const chosenVersion = GlobalManager.getChosenVersion();
        if (!chosenVersion) {
          return reject();
        } else if (loadedVersion !== chosenVersion) {
          this.loadingVersion = VersionService[chosenVersion];
          VersionService.loadAsGlobal(this.loadingVersion.abbreviation).then(() => {
            GlobalManager.setLoadedVersion(this.loadingVersion.abbreviation);
            resolve();
          });
        } else return resolve();
      });
    },

    createGame() {
      let verses = [];
      const verseCount = GlobalManager.getBibleData().to;
      for (let i = 0; i < 5; ++i) {
        let verse = GlobalManager.getBibleVerse(random(0, verseCount));
        verses.push(verse);
      }
      GlobalManager.setGameVerses(verses);
      GlobalManager.clearScore();

      this.$router.push("/game/round/1");
    }
  }
}
</script>



<style lang="less" scoped>
@import "../styles/variables.less";
@import "../styles/layout.less";

div.component.game-creation {
  .condensed-font();.uppercase();

  & > header, & > footer {
    flex-basis: 0 0 25vh !important;
    text-align: center;
    line-height: 25vh;
  }
  & > main {
    flex: 1 1 50vh !important;
  }
}
</style>