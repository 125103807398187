const LOCAL = 'localStorage';
const SESSION = 'sessionStorage';



// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const isAvailable = (type) => {
  let storage;
  try {
    storage = window[type];
    let x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0)
    ;
  }
}

const set = (type, key, value) => {
  window[type].setItem(key, JSON.stringify(value));
}

const get = (type, key) => {
  return JSON.parse(window[type].getItem(key));
}

/**
 * @return {Promise}
 */
const getAsync = (type, key) => {
  return new Promise((resolve/*, reject*/) => {
    const value = get(type, key);
    resolve(value);
  });
}

const remove = (type, key) => {
  window[type].removeItem(key);
}

const clear = (type) => {
  window[type].clear();
}

const setLanguageCodeToLocal    = (value) => { set(LOCAL, "languageCode", value); }
const getLanguageCodeFromLocal  = ()      => { return get(LOCAL, "languageCode"); }
const setChosenVersionToLocal   = (value) => { set(LOCAL, "chosenVersion", value); }
const getChosenVersionFromLocal = ()      => { return get(LOCAL, "chosenVersion"); }



export default {
  LOCAL,
  SESSION,

  isAvailable,
  set,
  get,
  getAsync,
  remove,
  clear,

  setLanguageCodeToLocal,
  getLanguageCodeFromLocal,
  setChosenVersionToLocal,
  getChosenVersionFromLocal,
}