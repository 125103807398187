<template>
  <div class="component loader">
    <div class="book">
      <span class="page turn"></span>
      <span class="page turn"></span>
      <span class="page turn"></span>
      <span class="page turn"></span>
      <span class="page turn"></span>
      <span class="page turn"></span>
      <span class="cover"></span>
      <span class="page"></span>
      <span class="cover turn">
        <span class="title">{{ title }}</span>
      </span>
    </div>
    <div v-if="legend" class="legend">{{ legend }}</div>
  </div>
</template>



<script>
export default {
  name: "BgLoader",

  props: { 
    title: {type: String, required: false},
    legend: {type: String, required: false},
  },
}
</script>



<style scoped lang="less">
@import "../styles/variables.less";

@bookWidth: 9.25rem;
@bookHeight: 12.5rem;
@pageMargin: 0.25rem;
@crossThickness: 0.5rem;
@crossWidth: 4rem;
@crossHeight: 6rem;

.reverse-ease {
  animation-timing-function: cubic-bezier(0.25, 0, 0.25, 0.9);
}

.legend {
  position: absolute;
  top: calc(@bookHeight + 5rem);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 2*@bookWidth;
  height: 1rem;
  text-align: center;
}

.book {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -@bookWidth;
  right: 0;
  margin: auto;
  width: 2*@bookWidth;
  height: @bookHeight;
  perspective: 70rem;
  animation-name: bookMove;
}
@keyframes bookMove {
  10% { left: -@bookWidth; }
  30% { left: 0 }
  70% { left: 0 }
  90% { left: -@bookWidth; }
}
.cover {
  top: 0;
  left: @bookWidth;
  background-color: @primary-color;
  transform: rotateY(0deg);
  width: @bookWidth;
  height: @bookHeight;
}
.page {
  top: @pageMargin;
  left: @bookWidth;
  background-color: white;
  transform: rotateY(0deg);
  width: @bookWidth - @pageMargin;
  height: @bookHeight - 2*@pageMargin;
}
.cover.turn::before, .cover.turn::after {
  position: absolute;
  display: block;
  background: white;
  content: "";
  opacity: 1;
  animation-name: openCoverElement;
}
@keyframes openCoverElement {
  15.5% { opacity: 1; }
  15.6% { opacity: 0; }
  77.2% { opacity: 0; }
  77.3% { opacity: 1; }
}
// Cross
// Before = horizontal part
// After = vertical part
.cover.turn::before {
  top: 2.5rem;
  left: (@bookWidth - @crossThickness)/2;
  width: @crossThickness;
  height: @crossHeight;
}
.cover.turn::after {
  top: 4.0rem;
  left: (@bookWidth - @crossWidth)/2;
  width: @crossWidth;
  height: @crossThickness;
}
.cover, .page {
  position: absolute;
  transform-origin: 0 0;
  border-radius: 0 5px 5px 0;
  box-shadow: inset 
    3px 0px 20px rgba(0, 0, 0, 0.2),
    0px 0px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.book, .turn, .turn::before, .turn::after, .title {
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-duration: 6s;
  animation-fill-mode: forwards;
}
.cover.turn {
  animation-name: openCover;
  z-index: 7;
  .title {
    position: absolute;
    width: 100%;
    text-align: center;
    padding-top: 9.5rem;
    animation-name: openCoverElement;
  }
}
@keyframes openCover {
  10% { transform: rotateY(   0deg); }
  30% { transform: rotateY(-180deg); }
  70% { transform: rotateY(-180deg); .reverse-ease(); }
  90% { transform: rotateY(   0deg); }
}
.page:nth-of-type(1) {
  animation-name: openPage1;
}
@keyframes openPage1 {
  11.0% { z-index: 6; transform: rotateY(   0deg); }
  17.0% { z-index: 6; }
  17.1% { z-index: 8; }
  33.0% { z-index: 8; transform: rotateY(-180deg); }
  67.0% { z-index: 8; transform: rotateY(-180deg); .reverse-ease(); }
  74.7% { z-index: 8; }
  74.8% { z-index: 6; }
  89.0% { z-index: 6; transform: rotateY(   0deg); }
}
.page:nth-of-type(2) {
  animation-name: openPage2;
}
@keyframes openPage2 {
  15.0% { z-index: 5; transform: rotateY(   0deg); }
  21.1% { z-index: 5; }
  21.2% { z-index: 9; }
  37.0% { z-index: 9; transform: rotateY(-180deg); }
  63.0% { z-index: 9; transform: rotateY(-180deg); .reverse-ease(); }
  70.7% { z-index: 9; }
  70.8% { z-index: 5; }
  85.0% { z-index: 5; transform: rotateY(   0deg); }
}
.page:nth-of-type(3) {
  animation-name: openPage3;
}
@keyframes openPage3 {
  20.0% { z-index:  4; transform: rotateY(   0deg); }
  25.5% { z-index:  4; }
  25.6% { z-index: 10; }
  41.0% { z-index: 10; transform: rotateY(-180deg); }
  59.0% { z-index: 10; transform: rotateY(-180deg); .reverse-ease(); }
  66.7% { z-index: 10; }
  66.8% { z-index:  4; }
  81.0% { z-index:  4; transform: rotateY(   0deg); }
}
.page:nth-of-type(4) {
  animation-name: openPage4;
}
@keyframes openPage4 {
  24.0% { z-index:  3; transform: rotateY(   0deg); }
  30.7% { z-index:  3; }
  30.8% { z-index: 11; }
  45.0% { z-index: 11; transform: rotateY(-150deg); }
  55.0% { z-index: 11; transform: rotateY(-150deg); .reverse-ease(); }
  61.6% { z-index: 11; }
  61.7% { z-index:  3; }
  77.0% { z-index:  3; transform: rotateY(   0deg); }
}
.page:nth-of-type(5) {
  animation-name: openPage5;
}
@keyframes openPage5 {
  27% { z-index: 2; transform: rotateY(  0deg); }
  48% { z-index: 2; transform: rotateY(-55deg); }
  52% { z-index: 2; transform: rotateY(-55deg); .reverse-ease(); }
  74% { z-index: 2; transform: rotateY(  0deg); }
}
.page:nth-of-type(6) {
  animation-name: openPage6;
}
@keyframes openPage6 {
  28% { z-index: 1; transform: rotateY(  0deg); }
  49% { z-index: 1; transform: rotateY(-30deg); }
  51% { z-index: 1; transform: rotateY(-30deg); .reverse-ease(); }
  73% { z-index: 1; transform: rotateY(  0deg); }
}
</style>
