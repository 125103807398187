import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import Game from '@/components/Game.vue'
import GameCreation from '@/components/GameCreation.vue'
import GameResult from '@/components/GameResult.vue'
import GameRound from '@/components/GameRound.vue'
import VersionConverter from '@/components/VersionConverter.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/game',
      name: 'Game',
      component: Game,
      children: [
        { 
          path: '',
          name: 'GameCreation',
          component: GameCreation,
        },
        { 
          path: 'round/:round',
          name: 'GameRound',
          component: GameRound,
        },
        {
          path: 'result',
          name: 'GameResult',
          component: GameResult,
        }
      ]
    },
    {
      path: '/convert/:source/:version/:language?',
      name: 'VersionConverter',
      component: VersionConverter
    }
  ]
});

export default router
