export const range = (from, to) => {
  let list = [];
  for (let i = from; i <= to; i++) {
    list.push(i);
  }
  return list;
}

export const hasProperty = (obj, prop) => {
  // https://eslint.org/docs/rules/no-prototype-builtins
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const random = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const newArrayWithValue = (size, value) => {
  let array = new Array(size);
  while(size--) array[size] = value;
  return array;
}

const baseChars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const convertBase = (inputString, inputBase, outputBase) => {
  if (inputBase < 2 || inputBase > 62 || outputBase < 2 || outputBase > 62) {
    throw "Bases between 2 and 62";
  }
  let inputValues = [], outputValues = [], remainder, position = 0;
  for (let i = 0; i < inputString.length; i++) {
    inputValues[i] = baseChars.indexOf(inputString[i]);
  }
  while (position < inputValues.length) {
    remainder = 0
    for (let i = position; i < inputValues.length; i++) {
      remainder = inputValues[i] + remainder * inputBase;
      inputValues[i] = Math.floor(remainder / outputBase);
      remainder -= inputValues[i] * outputBase;
      if (inputValues[i] == 0 && i == position) {
        position++;
      }
    }
    outputValues.push(remainder);
  }
  outputValues.reverse();
  let outputString = "";
  for (let i = 0; i < outputValues.length; i++) {
    outputString += baseChars[outputValues[i]];
  }
  return outputString;
}

export const isStandalone = () => {
  return (navigator.standalone || window.matchMedia("(display-mode: standalone)").matches);
}

export const MOBILE_OS_ANDROID = "Android";
export const MOBILE_OS_IOS = "iOS";
export const MOBILE_OS_OTHER = "Other";

// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export const getMobileOS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return MOBILE_OS_ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return MOBILE_OS_IOS;
    }

    return MOBILE_OS_OTHER;
}