<template>
  <div id="container" class="component home justify-around">
    <header>
      <img id="logo" alt="BibleGuesser logo" src="../assets/logo/logo2.svg">
      <p id="version">v{{ getVersion(0, 1) }}</p>
    </header>
    <main>
      <bg-scroll-picker 
        class="width-default"
        :values="versionValues" 
        :defaultSelection="versionDefault" 
        :flex="[2, 3]"
        :textAlign="['left', 'center']"
        :big="true"
        @select-all="onSelectVersion"
      ></bg-scroll-picker>
    </main>
    <footer>
      <bg-navigation @click="onNavigate" :buttons="[
        {type: 'icon', event: HELP_EVENT, value: 'help', size: 'small'}, // TODO tooltip about
        {type: 'text', event: PLAY_EVENT, value: tr('home.navigation.play', [], languageCode), size: 'big', uppercase: true},
        {type: 'icon', event: SHARE_EVENT, value: 'share-variant', size: 'small', disabled: true},// TODO tooltip challenge
      ]"></bg-navigation>
    </footer>
    <bg-modal ref="helpModal" :show="showHelpModal" :buttons="[]" :closable="true" @close="onHelpModalClose">
      <bg-tabs :tabs="[
        { 'id': 'rules', 'label': tr('home.help-modal.rules-tab.title', [], languageCode), selected: true },
        { 'id': 'about', 'label': tr('home.help-modal.about-tab.title', [], languageCode) }, 
      //{ 'id': 'changelog', 'label': tr('home.help-modal.changelog-tab.title', [], languageCode) }
      ]">
        <template v-slot:rules><p v-html="tr('home.help-modal.rules-tab.content:html', [], languageCode)"></p></template>
        <template v-slot:about>
          <h1>{{ Package.name }}<br/><span class="subtitle">v{{ getVersion(0, 2) }}{{ getVersionName() ? ' "' + getVersionName() + '"' : "" }}</span></h1>
          <div class="verse">
            <p class="verse-value" v-html="tr('home.help-modal.about-tab.verse.value', [], languageCode)"></p><br />
            <p class="verse-reference" v-html="'- ' + tr('home.help-modal.about-tab.verse.reference', [], languageCode)"></p>
          </div>
          <p class="center developed-by" v-html="tr('home.help-modal.about-tab.developed-by', [], languageCode)"></p>
          <p class="center hosted-by" v-html="tr('home.help-modal.about-tab.hosted-by', [], languageCode)"></p>
          <!-- TODO contact / report a bug / recaptcha / google analytics -->
          <h2>{{ tr("home.help-modal.about-tab.acknowledgments.title", [], languageCode) }}</h2>
          <p v-html="tr('home.help-modal.about-tab.acknowledgments.content:html', [], languageCode)"></p>
          <!-- TODO Frantz: daltonien -->
          <!-- TODO Daniel: sélection -->
          <!-- TODO traducteurs: Philippe/en ? Michel/de ? Carolina/es ? Daniel/pt ? -->
        </template>
        <!--<template v-slot:changelog
          ><p>TODO changelog</p
        ></template>--><!-- Parse CHANGELOG.md Translatable? -->
      </bg-tabs>
    </bg-modal>
    <bg-modal ref="domainModal" :show="!showUpdateModal && showDomainModal" @click="onDomainModalClick" :buttons="[
      {type: 'text+icon', value: ['BibleGuesser.com', 'open-in-new'], event: 'goToProduction'}
    ]" :closable="true" @close="onDomainModalClose">
      <h2><span class="flag-icon flag-icon-fr margin-right-10"></span>Environnement de test</h2>
      <p>Vous êtes sur un environnement de test. Rendez-vous sur <em>BibleGuesser.com</em> pour profiter de la version publique de BibleGuesser !</p>
      <h2><span class="flag-icon flag-icon-gb margin-right-10"></span>Test environment</h2>
      <p>You are on the test environment. Please go to <em>BibleGuesser.com</em>, so you'll be able to enjoy the public version of BibleGuesser!</p>
    </bg-modal>
    <bg-modal ref="updateModal" :show="showUpdateModal" @click="onUpdateModalClick" :buttons="[
      {type: 'text+icon', value: ['Mettre à jour / Update', 'refresh'], event: 'update'}
    ]">
      <h2><span class="flag-icon flag-icon-fr margin-right-10"></span>Mise à jour</h2>
      <p>Une mise à jour est disponible ! <em>{{ updateVersion ? updateVersion : "" }}</em></p>
      <h2><span class="flag-icon flag-icon-gb margin-right-10"></span>Update</h2>
      <p>An update is available! <em>{{ updateVersion ? updateVersion : "" }}</em></p>
    </bg-modal>
  </div>
</template>



<script>
import axios from "axios";
import BgModal from "@/components/Modal.vue";
import BgNavigation from "@/components/Navigation.vue";
import BgScrollPicker from "@/components/ScrollPicker.vue";
import BgTabs from "@/components/Tabs.vue";
import GlobalManager from "@/managers/global.manager.js";
import LanguageManager from "@/managers/language.manager.js";
import StorageManager from "@/managers/storage.manager.js";
import VersionService from "@/services/version.service.js";
import Package from "../../package.json"; // TODO set in future store as read-only


const HELP_EVENT = "help";
const PLAY_EVENT = "play";
const SHARE_EVENT = "share";

function getVersionValues(label) {
  let versionValues = [];
  const allVersionsByLanguageCode = VersionService.getAllByLanguageCode();
  for (const languageCode in allVersionsByLanguageCode) {
    const language = LanguageManager.getByCode(languageCode);
    let languageLabel = null;
    if (label) languageLabel = language[label];
    let languageData = {
      name: "<span class='flag-icon flag-icon-" + language.flagCode + (languageLabel ? " margin-right-10" : "") + "'></span>" + 
            (languageLabel ? "<span class='language-label'>" + languageLabel + "</span>" : ""),
      value: languageCode,
      children: []
    };
    for (const version of allVersionsByLanguageCode[languageCode]) {
      languageData.children.push({
        name: version.name,
        value: version.abbreviation
      });
    }
    versionValues.push(languageData);
  }
  return versionValues;
}

function getVersionDefault() {
  let versionDefault = [];
  const allVersionsByLanguageCode = VersionService.getAllByLanguageCode();
  const globalVersion = GlobalManager.getChosenVersion();
  const globalLanguageCode = GlobalManager.getLanguageCode();
  const storageVersion = StorageManager.getChosenVersionFromLocal();
  const storageLanguageCode = StorageManager.getLanguageCodeFromLocal();
  const detectedLanguageCode = LanguageManager.detect();
  if (globalVersion) {
    const cacheVersion = VersionService[globalVersion];
    versionDefault = [cacheVersion.language, cacheVersion.abbreviation];
  } else if (globalLanguageCode) {
    versionDefault = [globalLanguageCode, null];
  } else if (storageVersion) {
    const storedVersion = VersionService[storageVersion];
    versionDefault = [storedVersion.language, storedVersion.abbreviation];
  } else if (storageLanguageCode) {
    versionDefault = [storageLanguageCode, null];
  } else if (detectedLanguageCode in allVersionsByLanguageCode) {
    versionDefault = [detectedLanguageCode, null];
  } else {
    versionDefault = [LanguageManager.FR.code, null];
  }
  if (versionDefault[0] && !versionDefault[1]) {
    versionDefault[1] = allVersionsByLanguageCode[versionDefault[0]][0];
  }
  return versionDefault;
}

export default {
  name: 'Home',
  components: {
    BgModal,
    BgNavigation,
    BgScrollPicker, 
    BgTabs,
  },

  mounted() {
    // https://stackoverflow.com/questions/20591562/stop-browser-from-caching-a-text-file
    axios
      .get("/version?cache-bust=" + Date.now()) 
      .then(response => {
        const updateVersion = response.data.replace("\n", "");
        if (updateVersion !== Package.version) {
          this.updateVersion = updateVersion;
          this.showUpdateModal = true;
        }
      })
      .catch(error => {
        console.log(error);
      })
    ;
  },

  data() {
    return {
      versionValues: getVersionValues("nativeName"),
      versionDefault: getVersionDefault(),
      LanguageManager,
      VersionService,
      languageCode: LanguageManager.FR.code,
      Package,
      showHelpModal: false,
      showDomainModal: window.location.hostname !== "www.bibleguesser.com",
      showUpdateModal: false,
      updateVersion: null,
      HELP_EVENT, PLAY_EVENT, SHARE_EVENT,
    }
  },

  methods: {
    onSelectVersion(event) {
      this.languageCode  = event.selection[0];
      this.chosenVersion = event.selection[1];
    },
    play() {
      GlobalManager.setLanguageCode(this.languageCode);
      GlobalManager.setChosenVersion(this.chosenVersion);
      StorageManager.setLanguageCodeToLocal(this.languageCode);
      StorageManager.setChosenVersionToLocal(this.chosenVersion);
      this.$router.push("/game");
    },
    showShareModal() {
      console.log("Share!");
    },
    onNavigate(event) {
      switch (event) {
        case HELP_EVENT: this.showHelpModal = true; break;
        case PLAY_EVENT: this.play(); break;
        case SHARE_EVENT: this.showShareModal(); break;
      }
    },
    onHelpModalClose() {
      this.showHelpModal = false;
    },
    onDomainModalClose() {
      this.showDomainModal = false;
    },
    showChangelogTab() {
      this.$refs.helpModal.showTab("changelog");
    },
    getVersion(levelStart = 0, levelEnd = null) {
      if (levelEnd === null) levelEnd = levelStart;
      const versionParts = Package.version.split(".");
      let result = "";
      for (let i = levelStart; i <= levelEnd; ++i) {
        result += "." + versionParts[i];
      }
      return result.substr(1);
    },
    getVersionName() {
      const subversion = +this.getVersion(1);
      // Généalogie de Jésus, Mt 1
      return [null, "Abraham", "Isaac", "Jacob", "Juda", "Pharès", "Esrom", "Aram", "Aminadab", "Naasson", "Salmon", "Boaz", "Obed", "Isaï", "David", "Salomon", "Roboam", "Abia", "Asa", "Josaphat", "Joram", "Ozias", "Joatham", "Achaz", "Ézéchias", "Manassé", "Amon", "Josias", "Jéchonias", "Salathiel", "Zorobabel", "Abiud", "Éliakim", "Azor", "Sadok", "Achim", "Éliud", "Éléazar", "Matthan", "Jacob", "Joseph"][subversion];
    },
    onDomainModalClick(event) {
      switch (event) {
        case "goToProduction": window.location.href = "https://www.bibleguesser.com"; break;
      }
    },
    onUpdateModalClick(event) {
      switch (event) {
        case "update": window.location.reload(true); break;
      }
    },
  }
}
</script>



<style lang="less">
.flag-icon {
  &.margin-right-5 {
    margin-right: 5px;
  }
  &.margin-right-10 {
    margin-right: 10px;
  }
}
</style>


<style lang="less" scoped>
@import "../styles/variables.less";
@import "../styles/layout.less";

div.component.home {
  & > header {
    text-align: center;

    p#version {
      font-size: 75%;
      margin: 0;
      color: fade(white, 25%);
    }

    img#logo {
      width: 100%;
      -webkit-filter: drop-shadow(0px 0px 5px black);
      filter: drop-shadow(0px 0px 5px black);
    }
  }

  & > main {
    flex: 0 0 !important;

    .component.bg-scroll-picker::v-deep {
      padding: 20px;
    }
  }

  .verse { // TODO add component modal selector
    .verse-value {
      text-align: center;
      .bible-font();
      .italic();
      color: @primary-color;
      font-size: 125%;
      margin: 30px 20px 0;
    }

    .verse-reference {
      color: @primary-color;
      text-align: right;
      margin: -10px 20px 40px;
    }
  }

  h2 {
    margin-top: 30px;
  }
}


</style>