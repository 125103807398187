<template>
  <div id="container" class="component game-result justify-between">
    <header>
      <h1>{{ tr("game-result.title") }}</h1>
    </header>
    <main>
      <div class="game-summary">
        <p>
          <span class="points"><em>{{ getSum("points") }}</em><span class="max-score"> / 500</span></span>
          <span class="stars">
            {{ getSum("stars") }}<mdi-svg-icon :id="'star'" :size="24" :color="'yellow'"></mdi-svg-icon><span class="max-score"> / 15</span>
          </span>
        </p>
      </div>
      <div class="round-details">
        <div v-for="round in 5" :key="round" class="round-detail" :class="'round-detail-' + round">
          <button class="round-detail-header" v-on:click="onClickOnRoundDetailHeader(round)">
            <table>
              <tr>
                <td class="title">{{ tr("game-result.round") }} {{ round }}</td>
                <td class="points">{{ score[round].points }}<span class="max-score"> / 100</span></td>
                <td class="stars">
                  <span v-for="(entity, index) in ['book', 'chapter', 'verse']" :key="index">
                    <mdi-svg-icon v-if="score[round].stars > index" :id="'star'" :size="24" :color="'yellow'"></mdi-svg-icon>
                    <mdi-svg-icon v-else :id="'star-outline'" :size="24" :color="'gray'"></mdi-svg-icon>
                  </span>
                </td>
                <td class="icon">
                  <mdi-svg-icon v-if="round === showRound" :id="'unfold-less-horizontal'" :size="24" :color="'white'"></mdi-svg-icon>
                  <mdi-svg-icon v-else :id="'unfold-more-horizontal'" :size="24" :color="'primary'"></mdi-svg-icon>
                </td>
              </tr>
            </table>
          </button>
          <div class="round-detail-content">
            <p class="verse-value">{{ score[round].roundVerse.value }}</p>
            <p v-html="tr('result-summary:html', [score[round].selectedVerse.fullRef, score[round].roundVerse.fullRef])"></p>
            <p class="stars-detail">
              <span v-for="(entity, index) in ['book', 'chapter', 'verse']" :key="index">
                <mdi-svg-icon v-if="score[round].stars > index" :id="'check-circle'" :size="16" :color="'green'"></mdi-svg-icon>
                <mdi-svg-icon v-else :id="'close-circle'" :size="16" :color="'red'"></mdi-svg-icon>
                {{ tr('stars-tooltip.' + entity) }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <bg-navigation @click="onNavigate" :buttons="[
        {type: 'icon', event: 'home', value: 'home', size: 'small'},
        {type: 'icon', event: 'replay', value: 'replay', size: 'medium'},
        {type: 'icon', event: 'share', value: 'share-variant', size: 'small', disabled: true},
      ]"></bg-navigation>
    </footer>
  </div>
</template>



<script>
import MdiSvgIcon from "@/components/MdiSvgIcon.vue";
import BgNavigation from "@/components/Navigation.vue";
import GlobalManager from "@/managers/global.manager.js";

export default {
  name: 'BgGameResult',

  components: {
    BgNavigation,
    MdiSvgIcon,
  },

  beforeRouteEnter(to, from, next) {
    // https://router.vuejs.org/fr/guide/advanced/navigation-guards.html#interception-par-composant
    if (!GlobalManager.getScore()) next("/");
    else next();
  },

  data() {
    return {
      score: GlobalManager.getScore(),
      showRound: null,
    }
  },

  methods: {
    getSum(scoreField) {
      let sum = 0;
      for (let round = 1; round <= 5; ++round) {
        sum += this.score[round][scoreField];
      }
      return sum;
    },

    showShareModal() {
       // https://developer.mozilla.org/fr/docs/Web/API/Navigator/share
      /*window.navigator.share({
        title: "Bible Guesser",
        text: this.tr("game-result.navigation.share"),
        url: "https://bibleguesser.rinder.fr",
      });*/
      console.log("Share!");
    },

    onClickOnRoundDetailHeader(round) {
      if (round === this.showRound) {
        this.closeRound(round);
        this.showRound = null;
      } else {
        if (null !== this.showRound) this.closeRound(this.showRound);
        this.openRound(round);
        this.showRound = round;
      }
    },

    closeRound(round) {
      const roundDetailElement = document.querySelector(".round-detail-" + round);
      roundDetailElement.classList.remove("show");
      const otherRoundDetailElements = document.querySelectorAll(".round-detail:not(.round-detail-" + round + ")");
      for (let i = 0; i < otherRoundDetailElements.length; ++i) {
        otherRoundDetailElements[i].classList.remove("hide");
      }
    },

    openRound(round) {
      const roundDetailElement = document.querySelector(".round-detail-" + round);
      roundDetailElement.classList.add("show");
      const otherRoundDetailElements = document.querySelectorAll(".round-detail:not(.round-detail-" + round + ")");
      for (let i = 0; i < otherRoundDetailElements.length; ++i) {
        otherRoundDetailElements[i].classList.add("hide");
      }
    },
    onNavigate(event) {
      switch (event) {
        case "home": GlobalManager.clearScore(); this.$router.push("/"); break;
        case "replay": this.$router.push("/game"); break;
        case "share": this.showShareModal(); break;
      }
    },
  }
}
</script>
 


<style lang="less" scoped>
@import "../styles/variables.less";
@import "../styles/layout.less";
@import "../styles/neumorphism.less";


.max-score {
  color: fade(white, 25%);
}

div.component.game-result {
  & > * {
    text-align: center;
    padding: 20px;
  }
  & > main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;

    & > .game-summary {
      font-size: 32px;

      .points, .stars {
        margin: 10px 30px;
      }

      .stars > svg.mdi-star {
        display: inline-block;
        vertical-align: text-bottom;
        width: 32px;
        height: 32px;
      }

      .max-score {
        font-size: 50%;
      }
    }

    & > .round-details {
      border-top: 1px solid darken(@primary-color, 10%);;
      border-bottom: 1px solid darken(@primary-color, 10%);;

      & > .round-detail {
        & > .round-detail-header {
          height: 40px;
          width: 100%;
          vertical-align: middle;
          margin: 0;
          padding: 0;
          border: 0;
          border-radius: 0;
          overflow-y: hidden;
          background: lighten(@background-color, 10%);
          transition: height 0.1s linear;

          &:hover {
            background: darken(@primary-color, 10%);

            & > table td.icon::v-deep svg.mdi path {
              fill: white;
            }
          }

          &:active {
            transform: scale(1);
          }

          & > table {
            width: 100%;

            td {
              white-space: nowrap;

              font-size: 1em;
              @media (max-width: 500px) { font-size: calc(0.9*1em); }
              @media (max-width: 400px) { font-size: calc(0.8*1em); }
              @media (max-width: 300px) { font-size: calc(0.7*1em); }
              @media (max-width: 200px) { font-size: calc(0.6*1em); }
            }

            td.title {
              width: 30%;
              text-align: left;
              padding-left: 10px;
            }

            td.points {
              width: 20%;
              text-align: right;

              .max-score {
                font-size: 75%;
              }
            }

            td.stars {
              width: 30%;
              text-align: right;
              position: relative;

              // https://www.w3schools.com/css/css_tooltip.asp
              .tooltip {
                visibility: hidden;
                width: 300px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                position: absolute;
                z-index: 1;
                top: 0;
                right: 100%;
                margin-right: 5px;
                opacity: 0;
                transition: opacity 1s;
                font-size: 75%;

                &::after {
                  content: " ";
                  position: absolute;
                  top: 50%;
                  left: 100%;
                  margin-top: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: transparent transparent transparent black;
                }

                & > span {
                  display: inline;
                  margin: 5px 10px;
                  line-height: 16px;

                  & > svg.mdi {
                    display: inline;
                    margin: 0 2px;
                    vertical-align: middle;
                  }
                }
              }

              &:hover .tooltip {
                visibility: visible;
                opacity: 1;
              }

              & > span > svg.mdi {
                display: inline;
                margin: 0;
              }

              td.icon {
                width: 20%;
                text-align: right;
                padding-right: 10px;

                &::v-deep svg.mdi {
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                }
              }
            }
          }
        }

        /*&:has(> .round-detail-content.show) .round-detail-content:not(.show) {
          height: 5;
          background: red;
        }*/

        & > .round-detail-content {
          overflow: hidden;
          height: 0;
          transition: height 0.1s linear;
          background: lighten(@background-color, 10%);
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-around;

          p {
            margin: 0;
          }

          p.verse-value {
            .bible-font();
            .italic();
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 10px;
            text-align: justify;

            @supports (-webkit-line-clamp: 3) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          } 

          p.stars-detail > span {
            display: inline-block;
            margin: 5px 10px;
            line-height: 16px;
            .condensed-font();
            .uppercase();
            font-size: 80%;

            & > svg.mdi {
              display: inline;
              margin: 0 5px;
              vertical-align: middle;
            }
          }
        }

        &.show {
          .round-detail-header {
            background: darken(@primary-color, 10%);
          }

          .round-detail-content {
            height: 160px;
          }
        }

        &.hide {
          .round-detail-header {
            height: 0;
          }
        }
      }
    }
  }
}
</style>