import Vue from 'vue';
import VueScrollPicker from 'vue-scroll-picker';
import App from '@/components/App.vue';
import GlobalManager from "@/managers/global.manager.js";
import LanguageManager from "@/managers/language.manager.js";
import router from '@/router.js';
import '@/main.css';
import '@/main.less';

Vue.config.productionTip = false;

Vue.use(VueScrollPicker);

Vue.mixin({
  methods: {
    tr: (key, data, lang = GlobalManager.getLanguageCode()) => LanguageManager.translate(key, data, lang)
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
