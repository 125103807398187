<template>
  <div class="component bg-scroll-picker">
    <scroll-picker-group :class="big ? 'big' : null">
      <scroll-picker v-for="i in range" :key="i" :placeholder="placeholders[i] || null" :options="valuesAtLevel(i)" v-model="selection[i]" empty="" @input="onInput(i)" :class="['flex-' + flex[i], textAlign.length > i && textAlign[i] ? 'text-align-' + textAlign[i] : null]"></scroll-picker>
    </scroll-picker-group>
  </div>
</template>



<script>
import { range, hasProperty, newArrayWithValue } from "@/helpers.js";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker";

function getObjectDepthRecursively(o) {
  if (Array.isArray(o)) {
    if (o.length === 0 || !hasProperty(o[0], "value")) return 1;
    let maxDepth = 0;
    for (let i = 0; i < o.length; ++i) {
      const d = getObjectDepthRecursively(o[i].children);
      if (d > maxDepth) {
        maxDepth = d;
      }
    }
    return 1 + maxDepth;
  }
  if (typeof o !== "object" || Object.keys(o).length < 1) {
    return 0;
  }//console.log("object");
  let maxDepth = 0;
  for (const i in o) {
    const d = getObjectDepthRecursively(o[i]);
    if (d > maxDepth) {
      maxDepth = d;
    }
  }
  return 1 + maxDepth;
}

function textAlignValidator(valueArray) {
  let valid = true;
  for (const item of valueArray) {
    valid = valid && ["left", "center", "right"].includes(item);
  }
  return valid;
}

function flexValidator(valueArray) {
  let valid = true;
  for (const item of valueArray) {
    valid = valid && Number.isInteger(item);
  }
  return valid;
}

export default {
  name: "BgScrollPicker",

  emits: ['select-level', 'select-all'],

  data() {
    return {
      selection: [],
    }
  },

  props: { 
    values: {
      type: [Array, Object], 
      required: true, 
    },
    placeholders: {type: Array, default: () => []},
    defaultSelection: {type: Array, default: () => []},
    flex: {
      type: Array, 
      default: () => newArrayWithValue(8, 1), 
      validator: value => flexValidator(value)
    },
    textAlign: {
      type: Array, 
      default: () => newArrayWithValue(8, "center"), 
      validator: value => textAlignValidator(value)
    },
    big: {type: Boolean, default: false},
  },

  components: {
    ScrollPicker, 
    ScrollPickerGroup,
  },

  computed: {
    depth() { return getObjectDepthRecursively(this.values); },

    range() { return range(0, this.depth - 1); },
  },


  mounted: function() {
    this.setSelection(this.defaultSelection);
  },

  methods: {
    setSelection(values, level = 0) {
      this.selection[level] = values[level];
      if (level + 1 < values.length) {
        setTimeout(() => { this.setSelection(values, level + 1) }, 100);
      }
    },

    valuesAtLevel(level) {
      let values = this.values;
      for (let i = 0; i < level; ++i) {
        // If a precedent level has not been chosen, return empty array
        if (!this.selection[i]) return [];
        // If object, subvalues are the value associated with the previous selection
        if (!Array.isArray(values) && hasProperty(values, this.selection[i])) {
          values = values[this.selection[i]];
          continue;
        }
        // If array, subvalues are
        if (Array.isArray(values)) { 
          let selectionData = values.find(v => v.value == this.selection[i]);
          if (selectionData) {
            values = hasProperty(selectionData, "children") ? selectionData.children : [];
            continue;
          }
        }
        // Else
        values = [];
      }//console.log(level);console.log(values);
      return Array.isArray(values) ? values : Object.keys(values);
    },

    onInput(level) {
      this.$emit("select-level", {level: level, value: this.selection[level]});
      if (level === this.depth - 1) {
        this.$emit("select-all", {selection: this.selection});
      }
    }
  },
}
</script>



<style lang="less">
@import "../styles/variables.less";

// vue-scroll-picker
@picker-height: 5em; // initial: 10em; /!\ Need a default selection if <> 10em
@picker-selection-color: @primary-color;
@picker-border-thickness: 1px;
@picker-border: @picker-border-thickness solid @picker-selection-color;
@picker-selection-height: 2.0em;
@picker-selection-margin: calc(50% - @picker-selection-height/2);
@picker-item-font-size: 1.5em;
@picker-item-font-weight: normal;
@picker-item-color: inherit;
@picker-item-selected-font-weight: bold;
@picker-item-selected-color: @picker-selection-color;

.vue-scroll-picker-group {
  display: flex;
  flex-wrap: nowrap;

  &.big .vue-scroll-picker {
    height: calc(1.6*@picker-height) !important; 
    @media (max-width: 500px) { height: calc(0.9*1.6*@picker-height) !important; }
    @media (max-width: 400px) { height: calc(0.8*1.6*@picker-height) !important; }
    @media (max-width: 300px) { height: calc(0.7*1.6*@picker-height) !important; }
    @media (max-width: 200px) { height: calc(0.6*1.6*@picker-height) !important; }
  }
}

.vue-scroll-picker {
  height: @picker-height !important; 
  @media (max-width: 500px) { height: calc(0.9*@picker-height) !important; }
  @media (max-width: 400px) { height: calc(0.8*@picker-height) !important; }
  @media (max-width: 300px) { height: calc(0.7*@picker-height) !important; }
  @media (max-width: 200px) { height: calc(0.6*@picker-height) !important; }

  .regular-font();

  &.flex-0 { flex: 0; }
  &.flex-1 { flex: 1; }
  &.flex-2 { flex: 2; }
  &.flex-3 { flex: 3; }
  &.flex-4 { flex: 4; }
  &.flex-5 { flex: 5; }
  &.flex-6 { flex: 6; }
  &.flex-7 { flex: 7; }
  &.flex-8 { flex: 8; }
  &.flex-9 { flex: 9; }

  &.text-align-left   .vue-scroll-picker-item { text-align: left;   padding-left:  10px; }
  &.text-align-center .vue-scroll-picker-item { text-align: center;                      }
  &.text-align-right  .vue-scroll-picker-item { text-align: right;  padding-right: 10px; }

  .vue-scroll-picker-item {
    font-weight: @picker-item-font-weight;
    font-size: @picker-item-font-size;
    color: @picker-item-color;
    padding-top: 1px; // fix
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    //text-overflow: fade(10px);
    .condensed-font();

    @media (max-width: 500px) { font-size: calc(0.9*@picker-item-font-size); }
    @media (max-width: 400px) { font-size: calc(0.8*@picker-item-font-size); }
    @media (max-width: 300px) { font-size: calc(0.7*@picker-item-font-size); }
    @media (max-width: 200px) { font-size: calc(0.6*@picker-item-font-size); }

    &::after { // fade(10px)
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 10px;
      background-image: linear-gradient(to right, fade(@background-color, 0%), @background-color);
      //z-index: 2;
      pointer-events: none; 
     }

    &.-selected {
      font-weight: @picker-item-selected-font-weight;
      color: @picker-item-selected-color;
    }

    &.-placeholder {
      text-transform: uppercase;
      font-size: 0.8em;
      font-weight: @picker-item-selected-font-weight;
      color: #aaa;
    }
  }

  .vue-scroll-picker-layer > .top,
  .vue-scroll-picker-layer > .bottom {
    height: calc(50% - @picker-selection-height/2);
    @media (max-width: 500px) { height: calc(50% - 0.9*@picker-selection-height/2); }
    @media (max-width: 400px) { height: calc(50% - 0.8*@picker-selection-height/2); }
    @media (max-width: 300px) { height: calc(50% - 0.7*@picker-selection-height/2); }
    @media (max-width: 200px) { height: calc(50% - 0.6*@picker-selection-height/2); }
  }
  .vue-scroll-picker-layer > .top {
    border-bottom: @picker-border;
    background: linear-gradient(@background-color, fade(@background-color, 50%));
  }
  .vue-scroll-picker-layer > .bottom {
    border-top: @picker-border;
    background: linear-gradient(fade(@background-color, 50%), @background-color);
  }
  .vue-scroll-picker-layer > .middle {
    top: calc(50% - @picker-selection-height/2);
    @media (max-width: 500px) { top: calc(50% - 0.9*@picker-selection-height/2); }
    @media (max-width: 400px) { top: calc(50% - 0.8*@picker-selection-height/2); }
    @media (max-width: 300px) { top: calc(50% - 0.7*@picker-selection-height/2); }
    @media (max-width: 200px) { top: calc(50% - 0.6*@picker-selection-height/2); }
    bottom: calc(50% - @picker-selection-height/2);
    @media (max-width: 500px) { bottom: calc(50% - 0.9*@picker-selection-height/2); }
    @media (max-width: 400px) { bottom: calc(50% - 0.8*@picker-selection-height/2); }
    @media (max-width: 300px) { bottom: calc(50% - 0.7*@picker-selection-height/2); }
    @media (max-width: 200px) { bottom: calc(50% - 0.6*@picker-selection-height/2); }
    background: none;
  }
}
</style>
