<template>
  <div id="container" class="component game-round justify-between">
    <main>
      <p class="bible-font fade">
        {{ getVersesString(-100, -1) }} <em
        >{{ $gameVerses[$route.params.round - 1].value }}</em
        > {{ getVersesString(+1, +100) }}
      </p>
    </main>
    <footer>
      <bg-scroll-picker :values="refsValues" :defaultSelection="defaultSelection" :flex="[5, 1, 1]" :textAlign="['left', 'right', 'right']"
        @select-all="onSelectRef" @select-level="onSelectPartialRef"></bg-scroll-picker>
      <bg-navigation @click="onNavigate" :buttons="[
        {type: 'icon', event: 'abort', value: 'home', size: 'small'},
        {type: 'icon', event: 'submit', value: 'check-bold', size: 'medium'},
        {type: 'icon', event: 'center', value: 'target', size: 'small'},
      ]"></bg-navigation
    ></footer>
    <bg-modal :title="tr('game-round.result-modal.title')" :show="showResultModal" @click="onResultModalClick" :buttons="[
      {type: 'text', value: tr('game-round.result-modal.' + (hasNextRound() ? 'next-round' : 'show-results')), event: 'next'}
    ]">
      <p v-if="score" class="score-points">{{ score.points }}<span class="max-score"> / 100</span></p>
      <p v-if="score" class="score-stars">
        <span class="tooltip">
          <span v-for="(entity, index) in ['book', 'chapter', 'verse']" :key="index">
            <mdi-svg-icon v-if="score.stars > index" :id="'check-circle'" :size="16" :color="'green'"></mdi-svg-icon>
            <mdi-svg-icon v-else :id="'close-circle'" :size="16" :color="'red'"></mdi-svg-icon>
            {{ tr('stars-tooltip.' + entity) }}
          </span>
        </span>
        <span v-for="(entity, index) in ['book', 'chapter', 'verse']" :key="index">
          <mdi-svg-icon v-if="score.stars > index" :id="'star'" :size="32" :color="'yellow'"></mdi-svg-icon>
          <mdi-svg-icon v-else :id="'star-outline'" :size="32" :color="'gray'"></mdi-svg-icon>
        </span>
      </p>
      <p v-html="tr('result-summary:html', [selectedVerse ? selectedVerse.fullRef : '', roundVerse ? roundVerse.fullRef : ''])"></p>
    </bg-modal>
    <bg-modal ref="abortModal" :show="showAbortModal" @click="onAbortModalClick" :closable="true" @close="onAbortModalClose" :small="true" :buttons="[
      {type: 'text', value: tr('game-round.abort-modal.cancel'), event: 'cancel', secondary: true},
      {type: 'text', value: tr('game-round.abort-modal.confirm'), event: 'confirm'}
    ]">
      <h2>{{ tr("game-round.abort-modal.title"/*"Êtes-vous sûr ?"*/) }}</h2>
      <p>{{ tr("game-round.abort-modal.content"/*"Vous êtes sur le point d'abandonner la partie en cours et de perdre le score en cours."*/) }}</p>
    </bg-modal>
  </div>
</template>



<script>
import MdiSvgIcon from "@/components/MdiSvgIcon.vue";
import BgModal from "@/components/Modal.vue";
import BgNavigation from "@/components/Navigation.vue";
import BgScrollPicker from "@/components/ScrollPicker.vue";
import { hasProperty } from "@/helpers.js";
import GlobalManager from "@/managers/global.manager.js";

const SCROLL_EVENT = "scroll";

const firstRoundWithoutScore = () => {
  if (!GlobalManager.getScore()) {
    return 1;
  }
  for (let round = 1; round <= 5; ++round) {
    if (!hasProperty(GlobalManager.getScore(), "" + round)) {
      return round;
    }
  }
}

export default {
  name: 'BgGameRound',

  components: {
    BgModal,
    BgNavigation,
    BgScrollPicker, 
    MdiSvgIcon,
  },

  beforeRouteEnter(to, from, next) {
    // https://router.vuejs.org/fr/guide/advanced/navigation-guards.html#interception-par-composant
    if (!GlobalManager.getGameVerses() || 
        !GlobalManager.getBibleRefs() ||
        !GlobalManager.getBibleData()
    ) {
      next("/"); 
    } else if ("/game/result" === from.path) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    let score = GlobalManager.getScore();
    let firstRoundWithoutScorePath = "/game/round/" + firstRoundWithoutScore();
    
    if (![1, 2, 3, 4, 5].includes(+to.params.round)) {
      next("/");
    } else if (+from.params.round === +to.params.round) { // Refresh
      if (1 === +to.params.round) {
        if (!score) next();
        else next(firstRoundWithoutScorePath);
      } else {
        if (!score) next("/");
        else if (hasProperty(score, "" + to.params.round)) next(firstRoundWithoutScorePath);
        else if (hasProperty(score, "" + (+to.params.round - 1))) next(); // Expected rounting (round 1)
        else next("/");
      }
    } else if (+from.params.round + 1 === +to.params.round) { // Next round
      if (!score) next("/");
      else if (hasProperty(score, "" + to.params.round)) next(firstRoundWithoutScorePath);
      else if (hasProperty(score, "" + (+to.params.round - 1))) next(); // Expected rounting
      else next("/");
    } else if (+from.params.round - 1 === +to.params.round) next(false); // Back
    else next("/");
  },

  data() {
    let refsValues = GlobalManager.getBibleRefs();
    let defaultSelection = [
      refsValues[0].value, 
      refsValues[0].children[0].value, 
      refsValues[0].children[0].children[0]
    ];

    return {
      refsValues: refsValues,
      textElement: null,
      verseElement: null,
      showResultModal: false,
      showAbortModal: false,
      defaultSelection: defaultSelection,
      selectedRef: defaultSelection,
      roundVerse: GlobalManager.getGameVerses()[this.$route.params.round - 1],
      selectedVerse: null,
      score: null,
    };
  },

  mounted() {
    this.roundVerse = GlobalManager.getGameVerses()[this.$route.params.round - 1];
    this.textElement = document.querySelector("main > p");
    this.textElement.addEventListener(SCROLL_EVENT, this.onScroll);
    this.verseElement = document.querySelector("main > p > em");

    setTimeout(() => {
      this.centerOnVerse(false);
    }, 250);
  },

  beforeDestroy() {
    this.textElement.removeEventListener(SCROLL_EVENT, this.onScroll);
  },

  watch: {
    '$route.params.round'(newRound) {
      this.roundVerse = GlobalManager.getGameVerses()[newRound - 1];
      setTimeout(() => {
        this.centerOnVerse(false);
      }, 250);
    }
  },

  methods: {
    getVersesData(relativeFrom, relativeTo) {
      return GlobalManager.getBibleVerseRange(
        this.roundVerse.index + relativeFrom, 
        this.roundVerse.index + relativeTo);
    },
    getVersesString(relativeFrom, relativeTo) {
      return this.getVersesData(relativeFrom, relativeTo)
        .map(v => v.value)
        .join(" ");
    },
    onScroll(event) { 
      // https://stackoverflow.com/questions/45822150/how-to-listen-to-the-window-scroll-event-in-a-vuejs-component
      console.log(event);
    },
    onSelectRef(event) {//console.log('onSelectRef');console.log(event.selection);
      this.selectedRef = event.selection;//console.log(this.selectedRef);
    },
    onSelectPartialRef(event) {//console.log('onSelectPartialRef');console.log(event.value);
      this.selectedRef[event.level] = event.value;
    },
    computeScore() {
      const bibleData = GlobalManager.getBibleData();
      const verseTotalCount = bibleData.to - bibleData.from + 1;
      const verseDistance = Math.abs(this.roundVerse.index - this.selectedVerse.index);
      const maxScore = 100;
      const truncateValue = 10;
      const narrowness = 20;
      const bookFound = this.roundVerse.bookName === this.selectedVerse.bookName;
      const chapterFound = bookFound && this.roundVerse.chapterIndexInBook === this.selectedVerse.chapterIndexInBook;
      const verseFound = verseDistance === 0;
      this.score = {
        points: Math.max(0, 
          Math.floor((maxScore + truncateValue)*Math.exp(-verseDistance/(verseTotalCount/narrowness))) - truncateValue
        ),
        stars: (bookFound ? 1 : 0) + (chapterFound ? 1 : 0) + (verseFound ? 1 : 0),
        roundVerse: this.roundVerse,
        selectedVerse: this.selectedVerse,
      };
    },
    hasNextRound() {
      return this.$route.params.round < 5;
    },
    abortGame() {
      GlobalManager.clearScore(); 
      this.$router.push("/");
    },
    submitRef() {//console.log(this.selectedRef);
      const bibleData = GlobalManager.getBibleData();//console.log(this.selectedRef);
      const book    = this.selectedRef[0];
      const chapter = this.selectedRef[1] || 1;
      const verse   = this.selectedRef[2] || 1;
      if (book <= bibleData.testaments[0].books.length) { // 1st testament
        //console.log(book);console.log(chapter);console.log(verse);
        this.selectedVerse = bibleData.testaments[0].books[book - 1].chapters[chapter - 1].verses[verse - 1];
      } else { // 2nd testament
        this.selectedVerse = bibleData.testaments[1].books[book - bibleData.testaments[0].books.length - 1].chapters[chapter - 1].verses[verse - 1];
      }
      this.selectedVerse = GlobalManager.getBibleVerse(this.selectedVerse.index);
      this.computeScore();
      GlobalManager.setScore(this.$route.params.round, this.score);
      this.showResultModal = true;
    },
    centerOnVerse(smooth = true) {
      this.verseElement.scrollIntoView({behavior: smooth ? "smooth" : "instant", block: "center", inline: "nearest"});
      this.textElement.classList.add("fade");
      setTimeout(() => this.textElement.classList.remove("fade"), 1000);
    },
    onNavigate(event) {
      switch (event) {
        case "abort": this.showAbortModal = true; break;
        case "submit": this.submitRef(); break;
        case "center": this.centerOnVerse(); break;
      }
    },
    goToNextRound() {
      this.showResultModal = false;
      this.$router.push("/game/" + (this.hasNextRound() ? "round/" + (+this.$route.params.round + 1) : "result"));
    },
    onResultModalClick(event) {
      switch (event) {
        case "next": this.goToNextRound(); break;
      }
    },
    onAbortModalClick(event) {
      switch (event) {
        case "cancel": this.showAbortModal = false; break;
        case "confirm": this.abortGame(); break;
      }
    },
    onAbortModalClose() {
      this.showAbortModal = false; 
    },
  },
};
</script>



<style lang="less" scoped>
@import "../styles/variables.less";
@import "../styles/layout.less";
@import "../styles/neumorphism.less";

@footer-picker-height: 80px;
@footer-navigation-height: 115px;
@footer-padding: 20px;
@footer-height: @footer-picker-height + @footer-navigation-height + 2*@footer-padding;

.max-score {
  color: fade(white, 25%);
  font-size: 50%;
}

div.component.game-round {
  & > main {
    font-size: 1.5em;
    line-height: 1.5em;
    @media (max-width: 500px) { font-size: calc(0.9*1.5em); line-height: calc(0.95*1.5em); }
    @media (max-width: 400px) { font-size: calc(0.8*1.5em); line-height: calc(0.90*1.5em); }
    @media (max-width: 300px) { font-size: calc(0.7*1.5em); line-height: calc(0.85*1.5em); }
    @media (max-width: 200px) { font-size: calc(0.6*1.5em); line-height: calc(0.80*1.5em); }
    text-align: justify;
    flex-grow: 1;
    overflow: scroll;
    padding: 20px;
    & > p {
      margin: 0;
      transition: color 0.5s ease-in;
      &.fade {
        color: fade(white, 20%);
        transition: color 0s;
      }
      em {
        font-weight: 600;
      }
    }
    &::before, &::after {
      content: " ";
      position: absolute;
      left: 0;
      width: 100%;
      height: 15%;
      /*@media not all and (min-resolution:.001dpcm) { // Safari hack
        height: 25px;
      }*/
      z-index: 10;
      overflow: hidden;
      pointer-events: none; // Allow to scroll through gradient (safari fix)
    }
    &::before {
      top: 0;
      background: linear-gradient(@background-color, fade(@background-color, 75%), fade(@background-color, 0%));  
    }
    &::after {
      bottom: @footer-height;
      @media (max-width: 500px) { bottom: calc(0.9*@footer-height); }
      @media (max-width: 400px) { bottom: calc(0.8*@footer-height); }
      @media (max-width: 300px) { bottom: calc(0.7*@footer-height); }
      @media (max-width: 200px) { bottom: calc(0.6*@footer-height); }
      background: linear-gradient(fade(@background-color, 0%), fade(@background-color, 75%), @background-color);
    }
  }

  & > footer {
    width: @max-width;
    height: @footer-height;
    background: @background-color;
    flex: 0 0 @footer-height !important;
    padding: @footer-padding;
    
    @media (max-width: 500px) { height: calc(0.9*@footer-height); flex: 0 0 calc(0.9*@footer-height) !important; }
    @media (max-width: 400px) { height: calc(0.8*@footer-height); flex: 0 0 calc(0.8*@footer-height) !important; }
    @media (max-width: 300px) { height: calc(0.7*@footer-height); flex: 0 0 calc(0.7*@footer-height) !important; }
    @media (max-width: 200px) { height: calc(0.6*@footer-height); flex: 0 0 calc(0.6*@footer-height) !important; }
  }
}

div.modal {
  p.score-points {
    font-size: 3em;
    color: @primary-color;
  }

  p.score-stars {
    position: relative;

    // https://www.w3schools.com/css/css_tooltip.asp
    .tooltip {
      visibility: hidden;
      width: 100px;
      background-color: black;
      color: #fff;
      text-align: left;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      margin-left: -50px;
      opacity: 0;
      transition: opacity 1s;
      font-size: 75%;

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
      }

      & > span {
        display: block;
        margin: 5px 10px;
        line-height: 16px;
        .condensed-font();
        .uppercase();

        & > svg.mdi {
          display: inline;
          margin: 0 5px;
          vertical-align: middle;
        }
      }
    }

    &:hover .tooltip {
      visibility: visible;
      opacity: 1;
    }

    & > span {
      display: inline-block;
    }

    svg.mdi {
      display: inline;
      margin: auto;
    }
  }
}
</style>