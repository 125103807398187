<template>
  <div class="component tabs">
    <div class="tab-header-container" :class="'tab-count-' + tabs.length">
      <span class="tab-header" v-for="tab in tabs" :key="tab.id" :class="['tab-header-' + tab.id, tab.selected ? 'selected' : null]" @click="showTab(tab.id)">
        {{ tab.label }}
      </span>
    </div>
    <div class="tab-content" v-for="tab in tabs" :key="tab.id" :class="['tab-content-' + tab.id, tab.selected ? 'show' : null]"
      ><slot :name="tab.id"></slot
    ></div>
  </div>
<!--slot name https://fr.vuejs.org/v2/guide/components-slots.html-->
</template>



<script>

const SELECT_EVENT = "select";

export default {
  name: 'BgTabs',

  emits: [SELECT_EVENT],

  props: { 
    tabs: {type: Array, required: true, validator: function(value) {
      let valid = true;
      for (const item of value) {
        valid = valid && item.id && item.label;
      }
      return valid;
    }},
  },

  /*data() {
    let selectedTab = null;
    for (let tab in this.tabs) {
      if (tab.selected) {console.log(tab);
        selectedTab = tab.id;
        break;
      }
    }
    return {
      selectedTab
    };
  },*/

  methods: {
    showTab(id) {
      //console.log("show tab " + id);
      document.querySelectorAll(".tab-header").forEach(e => e.classList.remove("selected"));
      document.querySelectorAll(".tab-content").forEach(e => e.classList.remove("show"));
      document.querySelector(".tab-header.tab-header-" + id).classList.add("selected");
      document.querySelector(".tab-content.tab-content-" + id).classList.add("show");
      this.$emit(SELECT_EVENT, id);
    }
  }
}

</script>



<style lang="less" scoped>
@import "../styles/variables.less";

.component.tabs {
  width: 100%;
  height: 100%;

  .tab-header-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    .tab-header {
      height: 50px;
      padding: 10px;
      cursor: pointer;

      .condensed-font();
      .uppercase();
      line-height: calc(50px - 2*10px - 5px);
      border-bottom: 1px solid @primary-color;
      transition: border-bottom 0.1s, color 0.1s;

      &.selected { 
        color: @primary-color; 
        border-bottom: 5px solid @primary-color;
      }
    }

    &.tab-count-1 .tab-header { width: calc(100.00%); }
    &.tab-count-2 .tab-header { width: calc( 50.00%); }
    &.tab-count-3 .tab-header { width: calc( 33.33%); }
    &.tab-count-4 .tab-header { width: calc( 25.00%); }
    &.tab-count-5 .tab-header { width: calc( 20.00%); }
    &.tab-count-6 .tab-header { width: calc( 16.66%); }
    &.tab-count-7 .tab-header { width: calc( 14.28%); }
    &.tab-count-8 .tab-header { width: calc( 12.50%); }
    &.tab-count-9 .tab-header { width: calc( 11.11%); }
  }

  .tab-content {
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px 0;
    overflow: scroll;
    display: none;
    white-space: pre-line;
    text-align: left;
    font-size: 80%;
    margin: auto;

    &.show {
      display: block;
    } 
    &::before, &::after {
      content: " ";
      position: absolute;
      left: 0;
      width: 100%;
      height: 25px;
      z-index: 10;
      overflow: hidden;
    }
    &::before {
      top: calc(20px + 50px);
      background: linear-gradient(@background-color, fade(@background-color, 75%), fade(@background-color, 0%));
    }
    &::after {
      bottom: 20px;
      background: linear-gradient(fade(@background-color, 0%), fade(@background-color, 75%), @background-color);
    }

    h1, .center {
      text-align: center;
    }

    h1 {
      font-weight: 500;
      
      .subtitle {
        font-weight: 300;
        font-size: 75%;
      }
    }
  }
}

</style>