<template>
  <div class="component navigation">
    <button v-for="button in buttons" :key="button.event" class="neu circle" :class="[button.disabled ? 'disabled' : 'primary', button.size]" v-on:click="onClick(button)">
      <span v-if="button.type === 'text'" :class="button.uppercase ? 'uppercase' : null">{{ button.value }}</span>
      <mdi-svg-icon v-if="button.type === 'icon'" :id="button.value" :size="iconSize(button.size)" :color="button.disabled ? 'gray' : 'white'"></mdi-svg-icon>
    </button>
  </div>
</template>



<script>
import MdiSvgIcon from "@/components/MdiSvgIcon.vue";

const CLICK_EVENT = "click";

export default {
  name: "BgNavigation",

  emits: [CLICK_EVENT],

  components: {
    MdiSvgIcon,
  },

  props: { 
    buttons: {type: Array, required: true, validator: function(value) {
      let valid = true;
      for (const item of value) {
        valid = valid 
          && ["icon", "text"].includes(item.type)
          && ["small", "medium", "big"].includes(item.size)
        ;
      }
      return valid;
    }},
  },

  methods: {
    onClick(button) {
      this.$emit(CLICK_EVENT, button.event);
    },

    iconSize(buttonSize) {
      switch (buttonSize) {
        case "small": return 24;
        case "medium": return 40;
        case "big": return 56;
      }
    }
  }
}

</script>



<style lang="less">
  button.big svg.mdi {
    width: 56px;
    height: 56px;
    @media (max-width: 500px) { width: calc(0.9*56px); height: calc(0.9*56px); }
    @media (max-width: 400px) { width: calc(0.8*56px); height: calc(0.8*56px); }
    @media (max-width: 300px) { width: calc(0.7*56px); height: calc(0.7*56px); }
    @media (max-width: 200px) { width: calc(0.6*56px); height: calc(0.6*56px); }
  }
  button.medium svg.mdi {
    width: 40px;
    height: 40px;
    @media (max-width: 500px) { width: calc(0.9*40px); height: calc(0.9*40px); }
    @media (max-width: 400px) { width: calc(0.8*40px); height: calc(0.8*40px); }
    @media (max-width: 300px) { width: calc(0.7*40px); height: calc(0.7*40px); }
    @media (max-width: 200px) { width: calc(0.6*40px); height: calc(0.6*40px); }
  }
  button.small svg.mdi {
    width: 24px;
    height: 24px;
    @media (max-width: 500px) { width: calc(0.9*24px); height: calc(0.9*24px); }
    @media (max-width: 400px) { width: calc(0.8*24px); height: calc(0.8*24px); }
    @media (max-width: 300px) { width: calc(0.7*24px); height: calc(0.7*24px); }
    @media (max-width: 200px) { width: calc(0.6*24px); height: calc(0.6*24px); }
  }
</style>

<style lang="less" scoped>
@import "../styles/neumorphism.less";

.component.navigation {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  button {
    display: inline-block;

    & .uppercase {
      .uppercase();
    }

    & > svg.mdi {
      margin: auto;
      path {
        fill: white;
      }
    }
  }
}
</style>