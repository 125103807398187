<template>
  <div>
    <h1>{{ $route.params.version }}</h1>
    <p>{{ versionData }}</p>
  </div>
</template>



<script>
import VersionService from "@/services/version.service.js";

const BookNames = {
  "de": ["1. Mose (Genesis)", "2. Mose (Exodus)", "3. Mose (Levitikus)", "4. Mose (Numeri)", "5. Mose (Deuteronomium)", "Josua", "Richter", "Rut", "1. Samuel", "2. Samuel", "1. Könige", "2. Könige", "1. Chronik", "2. Chronik", "Esra", "Nehemia", "Ester", "Hiob", "Psalmen", "Sprüche", "Prediger", "Hohelied", "Jesaja", "Jeremia", "Klagelieder", "Hesekiel", "Daniel", "Hosea", "Joel", "Amos", "Obadja", "Jona", "Micha", "Nahum", "Habakuk", "Zephanja", "Haggai", "Sacharja", "Maleachi", "Matthäus", "Markus", "Lukas", "Johannes", "Apostelgeschichte", "Römer", "1. Korinther", "2. Korinther", "Galater", "Epheser", "Philipper", "Kolosser", "1. Thessalonicher", "2. Thessalonicher", "1. Timotheus", "2. Timotheus", "Titus", "Philemon", "Hebräer", "Jakobus", "1. Petrus", "2. Petrus", "1. Johannes", "2. Johannes", "3. Johannes", "Judas", "Offenbarung"
  ],
  "es": ["Génesis", "Éxodo", "Levítico", "Números", "Deuteronomio", "Josué", "Jueces", "Rut", "1 Samuel", "2 Samuel", "1 Reyes", "2 Reyes", "1 Crónicas", "2 Crónicas", "Esdras", "Nehemías", "Ester", "Job", "Salmos", "Proverbios", "Eclesiastés", "Cantares", "Isaías", "Jeremías", "Lamentaciones", "Ezequiel", "Daniel", "Oseas", "Joel", "Amós", "Abdías", "Jonás", "Miqueas", "Nahúm", "Habacuc", "Sofonías", "Hageo", "Zacarías", "Malaquías", "Mateo", "Marcos", "Lucas", "Juan", "Hechos", "Romanos", "1 Corintios", "2 Corintios", "Gálatas", "Efesios", "Filipenses", "Colosenses", "1 Tesalonicenses", "2 Tesalonicenses", "1 Timoteo", "2 Timoteo", "Tito", "Filemón", "Hebreos", "Santiago", "1 Pedro", "2 Pedro", "1 Juan", "2 Juan", "3 Juan", "Judas", "Apocalipsis"
  ],
  "pt": ["Gênesis", "Êxodo", "Levítico", "Números", "Deuteronómio", "Josué", "Juízes", "Rute", "1 Samuel", "2 Samuel", "1 Reis", "2 Reis", "1 Crônicas", "2 Crônicas", "Esdras", "Neemias", "Ester", "Jó", "Salmos", "Provérbios", "Eclesiastes", "Cânticos dos Cânticos", "Isaías", "Jeremias", "Lamentações", "Ezequiel", "Daniel", "Oseias", "Joel", "Amós", "Obadias", "Jonas", "Miqueias", "Naum", "Habacuque", "Sofonias", "Ageu", "Zacarias", "Malaquias", "Mateus", "Marcos", "Lucas", "João", "Atos", "Romanos", "1 Coríntios", "2 Coríntios", "Gálatas", "Efésios", "Filipenses", "Colossenses", "1 Tessalonicenses", "2 Tessalonicenses", "1 Timóteo", "2 Timóteo", "Tito", "Filémon", "Hebreus", "Tiago", "1 Pedro", "2 Pedro", "1 João", "2 João", "3 João", "Judas", "Apocalipse"
  ],
}

const convertVideoPsalm = content => {
  let verseCounter = 0;
  let bible = {
    testaments: [],
    from: verseCounter
  };
  for (const _testament of content.Testaments) {
    let testament = {
      books: [],
      from: verseCounter
    };
    for (const _book of _testament.Books) {
      let book = {
        value: _book.Text,
        chapters: [],
        from: verseCounter
      };
      for (const _chapter of _book.Chapters) {
        let chapter = {
          verses: [],
          from: verseCounter
        }
        for (const _verse of _chapter.Verses) {
          chapter.verses.push({
            value: _verse.Text,
            index: verseCounter++
          });
        }
        chapter.to = verseCounter - 1;
        book.chapters.push(chapter);
      }
      book.to = verseCounter - 1;
      testament.books.push(book);
    }
    testament.to = verseCounter - 1;
    bible.testaments.push(testament);
  }
  bible.to = verseCounter - 1;
  return bible;
}

const convertBibleTools = content => {
  let verseCounter = 0;
  let bible = {
    testaments: [
      {books: []},
      {books: []},
    ],
  };
  let testament = 0;
  bible.from = bible.testaments[1].from = verseCounter;
  for (const _book of content["bible"]["book"]) {
    if (_book._name.includes("Matthew")) {
      bible.testaments[0].to = verseCounter - 1;
      testament = 1;
      bible.testaments[1].from = verseCounter;
    }
    let book = {
      value: _book["_name"],
      chapters: [],
      from: verseCounter
    };
    for (const _chapter of _book["chapter"]) {
      let chapter = {
        verses: [],
        from: verseCounter
      }
      for (const _verse of _chapter["verse"]) {
        chapter.verses.push({
          value: _verse["__text"],
          index: verseCounter++
        });
      }
      chapter.to = verseCounter - 1;
      book.chapters.push(chapter);
    }
    book.to = verseCounter - 1;
    bible.testaments[testament].books.push(book);
  }
  bible.to = bible.testaments[1].to = verseCounter - 1;
  return bible;
}

const convertThiagobodruk = (content, language) => {
  let verseCounter = 0;
  let bible = {
    testaments: [
      {books: []},
      {books: []},
    ],
  };
  let testament = 0;
  bible.from = bible.testaments[1].from = verseCounter;
  let _bookIndex = 0;
  console.log(content);
  for (const _book of content.default) {
    if (_book["name"].includes("Matthew")) {
      bible.testaments[0].to = verseCounter - 1;
      testament = 1;
      bible.testaments[1].from = verseCounter;
    }
    let book = {
      value: BookNames[language][_bookIndex],
      chapters: [],
      from: verseCounter
    };
    for (const _chapter of _book["chapters"]) {
      let chapter = {
        verses: [],
        from: verseCounter
      }
      for (const _verse of _chapter) {
        chapter.verses.push({
          value: _verse,
          index: verseCounter++
        });
      }
      chapter.to = verseCounter - 1;
      book.chapters.push(chapter);
    }
    book.to = verseCounter - 1;
    bible.testaments[testament].books.push(book);
    _bookIndex++;
  }
  bible.to = bible.testaments[1].to = verseCounter - 1;
  return bible;
}

export default {
  name: 'VersionConverter',

  data() {
    return {
      versionData: 'abc'
    }
  },

  mounted() {
    const source = this.$route.params.source;
    const versions = VersionService.getAllAsObject();
    const version = this.$route.params.version in versions ? versions[this.$route.params.version] : null;
    import("@/assets/versions/" + version.filename).then(content => {
      let bible = "";
      switch (source) {
        case "video-psalm": bible = convertVideoPsalm(content); break;
        case "bible-tools": bible = convertBibleTools(content); break;
        case "thiagobodruk": bible = convertThiagobodruk(content, this.$route.params.language); break;
      }

      console.log(JSON.stringify(bible));
      this.versionData = JSON.stringify(bible);
    });
  }
}
</script>



<style scoped lang="less">

</style>