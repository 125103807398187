// ISO 639-1 (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)

const FR = {
  code: "fr",
  flagCode: "fr",
  nativeName: "Français",
};
const EN = {
  code: "en",
  flagCode: "gb",
  nativeName: "English",
};
const DE = {
  code: "de",
  flagCode: "de",
  nativeName: "Deutsch",
};
const ES = {
  code: "es",
  flagCode: "es",
  nativeName: "Español",
};
const PT = {
  code: "pt",
  flagCode: "pt",
  nativeName: "Português",
};
const IT = {
  code: "it",
  flagCode: "it",
  nativeName: "Italiano",
};

import fr from "@/assets/translations/fr.json";
import en from "@/assets/translations/en.json";
import de from "@/assets/translations/de.json";
import es from "@/assets/translations/es.json";
import pt from "@/assets/translations/pt.json";
//import it from "@/assets/translations/it.json";

const defaultLanguage = EN;

const getAll = () => [FR, EN, DE, ES, PT, IT];

const getByCode = (code) => getAll().filter(language => language.code === code)[0];

const translations = {
  fr, 
  en,
  de,
  es,
  pt,
//it,
}

const jsonGet = (jsonPath, jsonObject) => {
  const result = jsonPath.split(".").reduce((o, i) => (i in o ? o[i] : []), jsonObject);
  return typeof result === "string" ? result : null;
}

const translate = (key, data, languageCode) => {
  let result = jsonGet(key, translations[languageCode]) || jsonGet(key, translations[defaultLanguage.code]) || key;
  if (result.includes("%")) {
    for (let i = 0; i < data.length; ++i) {
      result = result.replace("%", data[i]);
    }
  }
  return result;
}

const detect = () => {
  let language = null;
  if (navigator && navigator.language) language = navigator.language;
  /*global browser, chrome*/
  else if (browser && browser.i18n && browser.i18n.getUILanguage) language = browser.i18n.getUILanguage();
  else if (chrome && chrome.i18n && chrome.i18n.getUILanguage) language = chrome.i18n.getUILanguage();
  return language ? language.substr(0, 2).toLowerCase() : null;
}

export default {
  FR,
  EN,
  DE,
  ES,
  PT,
  IT,

  getAll,
  getByCode,
  translate,
  detect,
}