import GlobalManager from "@/managers/global.manager.js"; 
import LanguageManager from "@/managers/language.manager.js"; 
import { range } from "@/helpers.js";



// FR
const LSG = {
  language: LanguageManager.FR.code,
  abbreviation: "LSG",
  name: "Louis Segond (1910)",
  filename: "fr.lsg.json",
  id: 1,
};

const NBS = {
  language: LanguageManager.FR.code,
  abbreviation: "NBS",
  name: "Nouvelle Bible Segond",
  filename: "fr.nbs.json",
  id: 2,
};

const PDV = {
  language: LanguageManager.FR.code,
  abbreviation: "PDV",
  name: "Parole de vie",
  filename: "fr.pdv.json",
  id: 3,
};

const S21 = {
  language: LanguageManager.FR.code,
  abbreviation: "S21",
  name: "Segond 21",
  filename: "fr.s21.json",
  id: 4,
}

const SEM = {
  language: LanguageManager.FR.code,
  abbreviation: "SEM",
  name: "Semeur",
  filename: "fr.sem.json",
  id: 5,
}

// EN
const KJV = {
  language: LanguageManager.EN.code,
  abbreviation: "KJV",
  name: "King James Version",
  filename: "en.kjv.json",
  id: 6,
};

const NIV = {
  language: LanguageManager.EN.code,
  abbreviation: "NIV",
  name: "New International Version",
  filename: "en.niv.json",
  id: 7,
};

const NRS = {
  language: LanguageManager.EN.code,
  abbreviation: "NRS",
  name: "New Revised Standard Version",
  filename: "en.nrs.json",
  id: 8,
}

const NLT = {
  language: LanguageManager.EN.code,
  abbreviation: "NLT",
  name: "New Living Translation",
  filename: "en.nlt.json",
  id: 9,
}

const NKJ = {
  language: LanguageManager.EN.code,
  abbreviation: "NKJ",
  name: "New King James Version",
  filename: "en.nkj.json",
  id: 10,
}

const ESV = {
  language: LanguageManager.EN.code,
  abbreviation: "ESV",
  name: "English Standard Version",
  filename: "en.esv.json",
  id: 11,
}

// DE
const SCH = {
  language: LanguageManager.DE.code,
  abbreviation: "SCH",
  name: "Schlachter-Bibel",
  filename: "de.sch.json",
  id: 12,
}

// ES
const RVR = {
  language: LanguageManager.ES.code,
  abbreviation: "RVR",
  name: "Reina Valera",
  filename: "es.rvr.json",
  id: 13,
}

// PT
/*const AA = {
  language: LanguageManager.PT.code,
  abbreviation: "AA",
  name: "Almeida Revisada Imprensa Bíblica? Almeida Atualizada?",
  filename: "pt_aa.json",
}

const ACF = {
  language: LanguageManager.PT.code,
  abbreviation: "ACF",
  name: "Almeida Corrigida Fiel? Almeida Corrigida e Revisada Fiel?",
  filename: "pt_acf.json",
}*/

const NVI = {
  language: LanguageManager.PT.code,
  abbreviation: "NVI",
  name: "Nova Versão Internacional",
  filename: "pt.nvi.json",
  id: 14,
}

// IT

const getAllAsArray = () => Object.values(getAllAsObject());

const getAllAsObject = () => ({
  LSG, NBS, PDV, S21, SEM, // fr
  KJV, NIV, NRS, NLT, NKJ, ESV, // en
  SCH, // de
  RVR, // es
  NVI, // pt
  // it
})

const getAllByLanguageCode = (code = undefined) => {
  let allByLanguageCode = [];
  for (const version of getAllAsArray()) {
    if (!(version.language in allByLanguageCode)) {
      allByLanguageCode[version.language] = [];
    }
    allByLanguageCode[version.language].push(version);
  }
  return (undefined === code) ? allByLanguageCode : allByLanguageCode[code];
}

const getById = (id) => {
  for (const version of getAllAsArray()) {
    if (version.id === id) return version;
  }
  return null;
}

function buildRefsFromContent(versionContent) {
  let versionRefs = [];
  let bookNumber = 1;
  for (const testament of versionContent.testaments) {
    for (const book of testament.books) {
      let bookRefs = [];
      let chapterNumber = 1;
      for (const chapter of book.chapters) {
        bookRefs.push({
          name: chapterNumber,
          value: chapterNumber,
          children: range(1, chapter.to - chapter.from + 1)
        });
        ++chapterNumber;
      }
      versionRefs.push({
        name: book.value,
        value: bookNumber,
        children: bookRefs
      });
      ++bookNumber;
    }
  }
  return versionRefs;
}

const loadAsGlobal = (versionAbbreviation) => {
  const versions = getAllAsObject();
  const loadingVersion = versionAbbreviation in versions ? versions[versionAbbreviation] : null;
  return new Promise((resolve, reject) => {
    if (null === loadingVersion) {
      reject("Unknown version " + versionAbbreviation);
    } else {
      import("@/assets/versions/" + loadingVersion.filename).then(versionContent => {
        GlobalManager.setBibleData(versionContent);
        GlobalManager.setBibleRefs(buildRefsFromContent(versionContent));
        resolve();
      })
    }
  });
}



export default Object.assign({
  getAllAsArray,
  getAllAsObject,
  getAllByLanguageCode,
  getById,
  loadAsGlobal,
}, getAllAsObject());
